<template>
  <aos-vue animation="fade" duration="700" delay="400" easing="ease-in-out">
    <div class="mobile:mt-[80px] desktop:mt-[120px] block flex-col">
      <div
        class="flex mobile:gap-2 mobile:w-full desktop:w-auto justify-center desktop:gap-8 desktop:flex-row"
      >
        <BigButton
          :link="`#price`"
          class="!mt-0 mb-10 px-1 mx-0"
          :has-icon="false"
          :has-arrow="true"
          :blank="false"
          @click="goToPrice($event)"
        >
          К прайсу
        </BigButton>
        <BigButton
          :link="`mailto:m@mdk.is`"
          class="!mt-0 mb-10 px-1 mx-0"
          :has-icon="false"
          :has-tg="true"
          :blank="false"
        >
          Написать
        </BigButton>
      </div>
      <div class="mobile:text-[32px] text-white tablet:text-[48px] font-bold">
        О нас
      </div>
      <div
        class="mobile:text-[18px] mobile:leading-9 text-center tablet:text-[26px] mt-[40px]"
      >
        Агентство креативных решений. Воплотим вашу задачу в жизнь <br />с
        яркостью и вирусностью, которая заставит понервничать конкурентов.
        <br />
        <br />
        MDK — легендарный паблик и основа медиахолдинга с аудиторией 22+ млн
        человек. Больше 10 лет мы реализуем запоминающиеся проекты как рекламное
        агентство. Наша экпертиза в нативных интеграциях помогает нашим клиентам
        легко добиваться внимания аудитории.
      </div>
      <BigButton :blank="false" @click.prevent="openChatra"
        >Заказать рекламу в MDK</BigButton
      >
    </div>
  </aos-vue>
</template>

<script setup>
import BigButton from "@/components/BigButton.vue";

const goToPrice = (event) => {
  event.preventDefault();
  const targetId = event.target.getAttribute("href").substring(1);
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    window.scrollTo({
      top: targetElement.offsetTop - 120,
      behavior: "smooth",
    });
  }
};

const openChatra = () => {
  if (typeof window.Chatra !== "undefined") {
    const Chatra = window.Chatra;
    Chatra("openChat", true);
  } else {
    console.error(
      "Chatra не определен. Убедитесь, что скрипт чата Chatra подключен."
    );
  }
};
</script>
